export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            layout: 'dashboard',
            header_color: 'primary',
            header_justify: 'end'
        },
        component: () => import('VIEWS/customer/Dashboard.vue')
    }
]