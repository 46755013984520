<template>
    <section class="square-box">
        <div :id="_id" 
            class="drop-area" 
            @dragover="dragOver($event)" 
            @dragleave="dragLeave($event)" 
            @drop="drop($event)"
            v-if="!uploaded">

                <span class="drop_title ge--description-xs opensans-bold">
                    Arrastra y suelta tu archivo
                </span>
                <span class="ge--description-xs opensans-bold">o</span>

                <label :for="`input-file-${_id}`" :class="`btn btn-${variant}`">
                    <span class="ge--description-xs opensans-bold text-white">
                        Selecciona tu archivo
                    </span>

                    <input :id="`input-file-${_id}`" 
                        @change="changeFile(event)"
                        type="file"
                        hidden>
                </label>
        </div>
        <div class="drop-download cursor-pointer" 
            @click="downloadTigger()"
            v-if="uploaded">

            <span class="drop_title ge--description-sm opensans-bold">
                <img :src="ico_download">
                Descargar
            </span>
        </div>
    </section>
    <div class="drop-preview" v-if="!uploaded">
        
    </div>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useStore } from "vuex"

    // Images
    const ico_download = ref(require('ASSETS/icons/download'))

    // Hooks
    const store = useStore()

    // Injects

    // Emits
    const emit = defineEmits(['charged', 'download'])
    // Props
    const props = defineProps({
        _id: {
            required: true,
            type: String
        },
        variant: {
            required: false,
            type: String,
            default: 'primary',
            validator: function (value) {
                // El valor debe coincidir con una de estas cadenas de texto
                return ['primary', 'secondary', 'success', 'warning', 'danger', 'light'].includes(value)
            }
        },
        uploaded: {
            required: false,
            type: Boolean,
            default: false
        }
    })

    // Computeds
    const data_alert = computed(() => {
        return store.getters.getAlert
    })

    // Variables
    let drop_area = reactive({})
    let drop_title = reactive({})

    let file = reactive({})


    // Methods
    const dragOver = (event) => {
        event.preventDefault()
        drop_area.classList.add('active')
        drop_title.textContent = 'Suelta para subir archivo'
    }
    const dragLeave = (event) => {
        event.preventDefault()
        drop_area.classList.remove('active')
        drop_title.textContent = 'Arrastra y suelta tu archivo'
    }
    const drop = (event) => {
        event.preventDefault()
        drop_area.classList.remove('active')
        emit('charged', { id: props._id, file: event.dataTransfer.files[0] })
        drop_title.textContent = 'Arrastra y suelta tu archivo'
    }
    const changeFile = (event) => {
        drop_area.classList.add('active')
        let file = drop_area.querySelector(`#input-file-${props._id}`)
        emit('charged', { id: props._id, file: file.files[0] })
        drop_area.classList.remove('active')
    }
    const downloadTigger = () => {
        emit('download', props._id)
    }


    // Vue Cyclelife
    onMounted(() => {
        if(!props.downloaded) {
            drop_area = document.getElementById(props._id)
            drop_title = drop_area.querySelector('.drop_title')
        }
    })

</script>

<style lang="scss" scoped>
    .square-box {
        position: relative;
        width: 100%;
        overflow: hidden;

        .drop-area, .drop-download {
            border: 3px dashed #DDDDDD;
            border-radius: 5px;
            padding: 10px;

            // square
            position:  absolute;
            top: 0; left: 0; bottom: 0; right: 0;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .drop-area.active, .drop-download:hover {
            background-color: #B8D4FE;
            color: black;
            border: 2px dashed #618AC9;
        }
    }
    .square-box:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .drop-preview {
        margin-top: 10px;
    }
</style>