<template>
    <div class="sidebar all-transitions-middle" 
        :style="(show_sidebar) ? 'width: 280px; padding: 10px;' : 'width: 50px; padding: 0px;'"> <!-- Aqui el padding -->

        <div :class="`sidebar__item ${(current_router_name == menu_item.name_route) ? 'selected' : ''}`"
            :style="(show_sidebar) ? 'padding: 10px 12px;' : 'padding: 10px 12px;'"
            v-for="(menu_item, menu_index) in menu" :key="menu_index"
            @click="$redirect(menu_item.name_route)">

            <div class="sb-img">
                <img :src="menu_item.img"
                    class="img-fluid">
            </div>
            <div class="sg-text flex-start-center ms-3"
                v-show="show_sidebar">

                <span class="ge--description-xs">
                    {{ menu_item.label }}
                </span>
            </div>

        </div>
    </div>
</template>

<script setup>
    import { ref, inject, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images
    //const ico_home = ref(require('ASSETS/icons/home'))
    // const ico_history = ref(require('ASSETS/icons/history'))
    import ico_home from 'ASSETS/icons/home'
    import ico_history from 'ASSETS/icons/history'
    import ico_report from 'ASSETS/icons/reports'
    

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Props
    const props = defineProps({
        
    })

    // Injects
    const $redirect = inject('$redirect')

    // Computeds
    const show_sidebar = computed(() => {
        return store.getters.getSidebar
    })
    const current_router_name = computed(() => {
        return router.currentRoute.value.name
    })

    // Variables
    const menu_customer = [
        { img: ico_history, name_route: 'dashboard', label: 'Historial de análisis' },
        { img: ico_report, name_route: 'observations', label: 'Estudios' },
    ]
    const menu_admin = [
        { img: ico_home, name_route: 'dashboard-admin', label: 'Pacientes' }
    ]

    const menu = (current_router_name.value.includes('admin')) ? menu_admin : menu_customer

    // Methods

</script>

<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    .sidebar {
        background-color: white;
        position: absolute;
        margin: 0px 3px 3px 3px !important;
        height: calc(100vh - 124px);
        box-shadow: inset 0 16px 9px -7px rgba(0, 0, 0, .14);
        padding-top: 24px !important;
        //box-shadow: inset 0 0rem 1rem rgb(0, 0, 0 / 15%) !important;
        //box-shadow: inset 5em 1em rgb(0, 0, 0 / 15%);

        @include downPixels(768px) {
            width: 50px !important;
            padding: 10px!important;
        }

        &__item {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 10px 12px;
            margin-bottom: 10px;
            cursor: pointer;

            @include downPixels(768px) {
                margin-bottom: 0px !important;
                padding: 10px 3px !important;
            }

            .sb-img {
                max-width: 50px;
                display: flex;
                justify-content: center;
                align-content: center;

                @include downPixels(768px) {
                    margin-right: 0px !important;
                }
            }
            .sg-text {
                @include downPixels(768px) {
                    display: none !important;
                }
            }
        }

        .selected {
            border-left: 3px solid #FF5148;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            //padding: 10px 8px !important;

            @include downPixels(768px) {
                border-left: 0px;
                border-bottom: 3px solid #FF5148;
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
            }
        }
    }
</style>