<template>
    <div id="modal" 
        @click.stop="closeModal('overlay')"
        :class="['modal', `${show_modal ? 'd-flex' : 'd-none'}`]" >

        <div @click.stop="closeModal('content')"
            :class="['row modal__container',`bg-${variant}`]" 
            :style="`max-width: ${max_width}px !important;`">

            <div class="col-12 p-0">
                <div class="row">
                    <div class="col-12 flex-end-center position-relative pe-4">
                        <button @click.stop="closeModal('button')" 
                            class="modal__container--button bg-transparent position-absolute" 
                            style="right: 10px; top: -10px; z-index: 4;">

                            <span class="ge--title-sm text-dark close">
                                &times;
                            </span>
                        </button>
                    </div>

                    <slot>
                        <!-- Slot default -->
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup> 

    // Props
    const props = defineProps({
        show_modal: {
            type: Boolean,
            required: true,
            default: false
        },
        overlay_close: {
            type: Boolean,
            require: false,
            default: true
        },
        variant: {
            type: String,
            require: false,
            default: 'transparent',
            validator: function (value) {
                return ['transparent', 'white', 'primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'].indexOf(value) !== -1
            }
        },
        max_width: {
            type: String,
            require: false,
            default: '900'
        }
    })

    // Emits
    const emits = defineEmits(['close'])

    // Methods
    const closeModal = (type) => {
        if(type === 'content') {
            return
        }
        if(type === 'overlay') {
            if(!props.overlay_close) {
                return
            }
        }
        emits('close')
    }
</script>

<style lang="scss" scoped>
    .modal {
        background-color: #000000bb;
        justify-content: center;
        align-items: center;
        z-index: 9;

        &__container {
            border-radius: 12px;
            width: 100%;
            z-index: 10;

            &--button {
                border-radius: 0.25rem !important;
                cursor: pointer;
                border: 0px;
            }
        }
    }
</style>