import { createStore } from 'vuex'
import LocalStorage from 'localStorage'

export default createStore({
    state: {
        show_sidebar: true,
        session: null,
        loading: {
            show: false,
            title: 'Cargando',
            subtitle: 'un momento...'
        },
        alert: {
            open: false,
            message: 'Error',
            variant: 'danger',
            color_text: 'white',
            timeout: null,
        },
    },
    getters: {
        getSession: state => {
            state.session = localStorage.getItem('G3NOMICS@session')
            try { state.session = JSON.parse(state.session) }
            catch(error) { state.session = null }

            //console.log(state.session, 'Getter: getSession')
            return state.session
        },
        getSidebar: state => {
            return state.show_sidebar
        },
        getLoad: (state) => {
            return state.loading
        },
        getAlert: (state) => {
            return state.alert
        },
    },
    mutations: {
        setSession: (state, session_data) => {
            state.session = session_data
            localStorage.setItem('G3NOMICS@session', JSON.stringify(session_data))
        },
        setSidebar: (state, new_sidebar) => {
            state.show_sidebar = new_sidebar
        },
        setLoad: (state, new_load) => {
            state.loading.show = new_load.show
            state.loading.title = (new_load.title !== undefined) ? new_load.title : 'Cargando'
            state.loading.subtitle = (new_load.subtitle !== undefined) ? new_load.subtitle : 'Espere un momento...'
            state.loading.timeout = (new_load.timeout !== undefined) ? new_load.timeout : null

            if(state.loading.timeout !== null) {
                setTimeout(() => {
                    state.loading.show = false
                }, state.loading.timeout)
            }
        },
        setAlert: (state, new_alert) => {
            state.alert.open = new_alert.open
            state.alert.message = (new_alert.message !== undefined) ? new_alert.message : ''
            state.alert.variant = (new_alert.variant !== undefined) ? new_alert.variant : 'success'
            state.alert.text_color = (new_alert.text_color !== undefined) ? new_alert.text_color : 'white'
            state.alert.timeout = (new_alert.timeout !== undefined) ? new_alert.timeout : 3000

            if(state.alert.timeout !== null) {
                setTimeout(() => {
                    state.alert.open = false
                }, state.alert.timeout)
            }
        },
    },
    actions: {
        // Session
        createSession: ({commit}, session_data) => {
            commit('setSession', session_data)
        },
        existSession: (state) => {
            state.session = localStorage.getItem('G3NOMICS@session')
            try { state.session = JSON.parse(state.session) }
            catch(error) { state.session = null}

            return state.session
        },
        deleteSession: ({commit}) => {
            commit('setSession', null)
        },
        // Loading
        showLoading: ({commit}) => {
            commit('setLoad', { show: true })
        },
        hiddenLoading: ({commit}) => {
            commit('setLoad', { show: false })
        },
        // Alerts
        showAlert: ({commit}, message) => {
            commit('setAlert', {
                open: true,
                message
            })
        },
        showError: ({commit}, message) => {
            commit('setAlert', {
                open: true,
                message,
                variant: 'danger'
            })
        },
    },
    modules: {

    }
})
