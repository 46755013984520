<template>
    <div class="loading" v-show="loading.show">
        <div class="loading__container flex-center">
            <div class="row">
                <div class="col-12">
                    <div class="lds-spinner">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <span class="ge--title-sm text-white">
                        {{ loading.title }}
                    </span>
                </div>
                <div class="col-12 p-0">
                    <span class="ge--description text-white">
                        {{ loading.subtitle }}
                    </span>
                </div>    
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useStore } from "vuex"

    // Images

    // Hooks
    const store = useStore()

    // Injects

    // Computeds
    const loading = computed(() => {
        return store.getters.getLoad
    })

    // Variables


    // Methods

</script>

<style lang="scss" scoped>
    .loading {
        position: fixed;
        height: 100vh;
        width: 100vw;

        justify-content: center;
        align-items: center;
        background-color: #000000bb;
        z-index: 9999;

        &__container {
            width: 100vw;
            height: 100vh;
            z-index: 10000;
            margin: 0;

            text-align: center;
        }

        .lds-spinner {
        color: official;
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        }
        .lds-spinner div {
        transform-origin: 40px 40px;
        animation: lds-spinner 1.2s linear infinite;
        }
        .lds-spinner div:after {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 37px;
        width: 6px;
        height: 18px;
        border-radius: 20%;
        background: #fff;
        }
        .lds-spinner div:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
        }
        .lds-spinner div:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
        }
        .lds-spinner div:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
        }
        .lds-spinner div:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
        }
        .lds-spinner div:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
        }
        .lds-spinner div:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
        }
        .lds-spinner div:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
        }
        .lds-spinner div:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
        }
        .lds-spinner div:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
        }
        .lds-spinner div:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
        }
        .lds-spinner div:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
        }
        .lds-spinner div:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
        }
        @keyframes lds-spinner {
        0% {
        opacity: 1;
        }
        100% {
        opacity: 0;
        }
        }

    }
</style>