<template>
    <nav class="navbar bg-white border shadow">
        <div class="container-fluid">
            <div class="flex-center">
                <div style="max-width: 240px;">
                    <img :src="ico_menu" 
                        @click="changeSidebar()"
                        class="img-fluid">    
                </div>
                <a class="navbar-brand">
                    <div style="max-width: 240px;">
                        <img :src="img_logo" 
                            class="img-fluid">    
                    </div>
                </a>
            </div>
            

            <form @click="logout()" class="flex-center cursor-pointer" role="search">
                <div class="flex-center" style="max-width: 20px;">
                    <img :src="ico_salir" class="img-fluid">
                </div>

                <div class="ps-2">
                    <span class="ge--description-xs text-primary">
                        <b>Salir</b>
                    </span>    
                </div>
            </form>
        </div>
    </nav>
</template>

<script setup>
    import { ref, computed, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images
    const ico_menu = ref(require('ASSETS/icons/menu'))
    const img_logo = ref(require('ASSETS/images/logo'))
    const ico_salir = ref(require('ASSETS/icons/salir'))

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Props
    const props = defineProps({
        
    })

    // Injects
    const $redirect = inject('$redirect')

    // Computeds
    const show_sidebar = computed(() => {
        return store.getters.getSidebar
    })

    // Variables

    // Methods
    const changeSidebar = () => {
        store.commit('setSidebar', !show_sidebar.value)
    }

    const logout = async() => {
        let session = await store.dispatch('existSession')
        await store.dispatch('deleteSession')
        $redirect(`login${(session.type == 'administrator') ? '-admin' : ''}`)
    }

</script>

<style lang="scss" scoped>
    
</style>