<template>
    <section class="input text-center mb-3">
        <span class="opensans-bold">
            <small>{{ label }}</small>
        </span>
        
        <div class="d-flex">
            <input :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                class="form-control text-center opensans-bold"
                :style="(type == 'password') ? 'padding: 6px 12px 6px 12px !important;' : ''"
                :placeholder="placeholder"
                :maxlength="max_length"
                :type="local_type"
                :max="max"
                :min="min"
                :disabled="disabled">

            <div class="input__eye" 
                @click="showPassword()" 
                v-if="type == 'password'">

                <div class="input__eye--image">
                    <div class="bg-white" style="width: 28px;">
                        <img :src="(local_type == 'password') ? icon_eye : icon_eye_slash">
                    </div>
                </div>
            </div>
        </div>

        <div class="text-start" 
            v-if="error_label !== null && error_label !== ''">

            <span class="error-label">
                {{ error_label }}
            </span>
        </div>
    </section>
</template>

<script setup>
    import { ref, onMounted } from "vue"

    // Images
    const icon_eye = ref(require('ASSETS/icons/eye-icon'))
    const icon_eye_slash = ref(require('ASSETS/icons/eye-slash-icon'))

    // Props
    const props = defineProps({
        modelValue: {
            required: false,
            type: [String, Number, Date],
            default: () => {
                return null
            }
        },
        type: {
            required: false,
            type: String,
            default: 'text',
            validator: function (value) {
                // El valor debe coincidir con una de estas cadenas de texto
                return ['text', 'date', 'number', 'password', 'email', 'tel'].includes(value)
            }
        },
        label: {
            required: false,
            type: String,
            default: ''
        },
        placeholder: {
            required: false,
            type: String,
            default: ''
        },
        max_length: {
            required: false,
            type: Number,
            default: null
        },
        max: {
            required: false,
            type: [Number, String],
            default: null
        },
        min: {
            required: false,
            type: [Number, String],
            default: null
        },
        error_label: {
            require: false,
            type: String,
            default: ''
        },
        disabled: {
            require: false,
            type: Boolean,
            default: false
        }
    })

    // Variables
    let local_type = ref(null)

    // Methods
    const showPassword = () => {
        local_type.value = (local_type.value === 'password') ? 'text' : 'password'
    }

    // Vue Cyclelife
    onMounted(() => {
        local_type.value = props.type
        // console.log(local_type.value)
    })

</script>

<style lang="scss">
    .input {
        text-align: left;
        position: relative;
        width: 100%;

        input {
            width: 100%;
            background-color: transparent !important;
        }

        &__eye {
            display: flex;
            justify-content: center;
            align-items: start;
            cursor: pointer;
            width: 0px;

            &--image {
                width: 20px;
                margin-left: -34px;

                img {
                    margin-bottom: -7px;
                    margin-left: 4px;
                    width: 20px;
                }
            }
        }
    }

    .error_label {
        color: #FF4539;
        font-size: 14px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 9999s ease-in-out 0s;
        -webkit-text-fill-color: #1C355E;
        caret-color: #1C355E;
    }
</style>