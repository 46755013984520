export default [
    {
        path: '/',
        name: 'login',
        meta: {
            layout: 'login',
            header_color: 'transparent',
            header_justify: 'start'
        },
        component: () => import('VIEWS/customer/Login.vue')
    }
]