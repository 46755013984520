<template>
    <section :class="`container-fluid py-2 bg-${color}`">
        <div class="row py-1">
            <div :class="`col-12 d-flex justify-content-${justify} align-items-center px-4`">
                <div class="d-flex align-items-center pe-4">
                    <span :class="`ge--description-xs ${(color == 'transparent' ? 'text-white' : 'text-white')}`">
                        <b>Canal de atención al cliente</b>
                    </span>    
                </div>
                <div class="d-flex align-items-center">
                    <div class="pe-2" style="max-width: 30px;">
                        <img :src="(color == 'transparent') ? img_phone_white : img_phone_white" class="img-fluid">
                    </div>
                    
                    <a href="tel:5591912016"
                        :class="`ge--description-xs ${(color == 'transparent' ? 'text-white' : 'text-white')}`"
                        class="cursor-pointer text-decoration-none">
                        <b>55 9191 2016</b>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { ref, onMounted } from "vue"

    // Images
    const img_phone_white = ref(require('ASSETS/icons/phone_white'))
    const img_phone = ref(require('ASSETS/icons/phone'))
    const icon_ing = ref(require('ASSETS/icons/instagram-icon')) // important, no delete

    // Props
    const props = defineProps({
        color: {
            required: false,
            type: String,
            default: 'primary',
            validator: function (value) {
                // El valor debe coincidir con una de estas cadenas de texto
                return ['primary','transparent'].includes(value)

            }
        },
        justify: {
            required: false,
            type: String,
            default: 'end',
            validator: function (value) {
                // El valor debe coincidir con una de estas cadenas de texto
                return ['start','center','end'].includes(value)

            }
        },
    })

</script>

<style lang="scss" scoped>
    
</style>