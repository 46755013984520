export default [
    {
        path: '/recovery-admin',
        name: 'recovery-admin',
        meta: {
            layout: 'login',
            header_color: 'transparent',
            header_justify: 'start'
        },
        component: () => import('VIEWS/administrator/Recovery.vue')
    }
]