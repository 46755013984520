import { createRouter, createWebHistory } from 'vue-router'
import _Store from '@/store'

// Customers
import Login from 'ROUTER/modules/login.js'
import Dashboard from 'ROUTER/modules/dashboard.js'
import Reports from 'ROUTER/modules/reports.js'
// import Test from 'ROUTER/modules/test.js'

// Administrator
import LoginAdmin from 'ROUTER/modules/loginadmin.js'
import RecoveryAdmin from 'ROUTER/modules/recoveryadmin.js'
import DashboardAdmin from 'ROUTER/modules/dashboardadmin.js'

const routes = [
    ...Login,
    ...LoginAdmin,
    ...RecoveryAdmin,
    ...Dashboard,
    ...DashboardAdmin,
    ...Reports,
    // ...Test
    {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "about" */ 'COMPONENTS/Page404.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// Router before each validations
router.beforeEach( async(to, from, next) => {
    // console.log(to, 'to')
    // console.log(from, 'from')

    /*** CHECK SESSION INTERNAL ***/
    let session = await _Store.dispatch('existSession')
    // console.log('BEFORE EACH ROUTER SESSION:', session)

    
    // Hidden views in specifics cases
    const session_views = ['dashboard', 'dashboard-admin', 'reports']
    const no_session_views = ['login', 'login-admin', 'recovery-admin']

    const admin_views = ['dashboard-admin']
    const clients_views = ['dashboard', 'reports']

    if(session == null && session_views.includes(to.name)) {
        next({name: (no_session_views.includes(from.name)) ? from.name : 'login'})
    }
    else if(session !== null) {
        if(no_session_views.includes(to.name)) {
            next({ name: `dashboard${(session.type == 'administrator') ? '-admin' : ''}` })
        }

        // Validate routes for type session
        if(session.type == 'administrator' && clients_views.includes(to.name)) {
            next({ name: 'dashboard-admin' })
        }
        else if(session.type == 'client' && admin_views.includes(to.name)) {
            next({ name: 'dashboard' })
        }
    }

    next()
})

export default router
