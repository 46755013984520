<template>
    <div id="template-login" class="p-0">
        <ge-header :color="header_color" :justify="header_justify">
        </ge-header>

        <slot></slot>
	</div>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images
    const bg_login = ref(require('ASSETS/images/background_login'))

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds
    const header_color = computed(() => {
        return router.currentRoute.value.meta.header_color || 'primary'
    })
    const header_justify = computed(() => {
        return router.currentRoute.value.meta.header_justify || 'end'
    })

    // Variables

    // Methods

</script>


<style lang="scss">
    @import 'ASSETS/scss/minxin.scss';

    #template-login {
        overflow: hidden;
        height: 100vh;
        width: 100vw;

        // @include upPixels(768px) {
            background-image: url('../assets/images/background_login.jpg');
            background-repeat: no-repeat;
            background-position: center start;
            background-size: cover;
        // }
    }
</style>