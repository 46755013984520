export default [
    {
        path: '/observations',
        name: 'observations',
        meta: {
            layout: 'dashboard',
            header_color: 'primary',
            header_justify: 'end'
        },
        component: () => import('VIEWS/customer/Reports.vue')
    }
]