<template>
    <div id="template-dashboard" class="p-0">
        <ge-header :color="header_color" :justify="header_justify">
        </ge-header>
        <ge-navbar></ge-navbar>
        <ge-sidebar></ge-sidebar>

        <section class="section_main bg-light all-transitions-middle" 
            :style="`${(show_sidebar) ? 
                'padding: 24px 24px 24px 306px;' : 
                'padding: 24px 24px 24px 76px;' }`">

                <slot></slot>
        </section>
	</div>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds
    const header_color = computed(() => {
        return router.currentRoute.value.meta.header_color || 'primary'
    })
    const header_justify = computed(() => {
        return router.currentRoute.value.meta.header_justify || 'end'
    })
    const show_sidebar = computed(() => {
        return store.getters.getSidebar
    })

    // Variables

    // Methods

</script>


<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    #template-dashboard {
        // height: calc(100vh - 128px);
        height: 100vh;

        .section_main {
            height: calc(100vh - 128px);

            @include downPixels(768px) {
                padding: 24px 24px 24px 76px !important;
            }
        }
    }
</style>