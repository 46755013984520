export default [
    {
        path: '/login-admin',
        name: 'login-admin',
        meta: {
            layout: 'login',
            header_color: 'transparent',
            header_justify: 'start'
        },
        component: () => import('VIEWS/administrator/Login.vue')
    }
]