import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

global.CONFIG = require('./page.config.js')

// import BOOTSTRAP
import 'ASSETS/scss/bootstrap/bootstrap.scss'
// import CUSTOM
import 'ASSETS/scss/custom.scss'


// imports LIBS
import _Request from './libs/sendRequest.js'
import _redirect from './libs/redirect.js'
import _informativeDate from './libs/informativeDate.js'


// import GLOBAL COMPONENTS
import { Input, Header, Navbar, Sidebar, Table, Modal, Loading, Alert, DropFile } from './components/index.js'


// TEMPLATES
import T_login from 'TEMPLATES/Tlogin'
import T_dashboard from 'TEMPLATES/Tdashboard'


// Create App
const app = createApp(App)
app.use(store)
app.use(router)
// templates
app.component('login', T_login)
app.component('dashboard', T_dashboard)
// components
app.component('ge-input', Input)
app.component('ge-header', Header)
app.component('ge-navbar', Navbar)
app.component('ge-sidebar', Sidebar)
app.component('ge-table', Table)
app.component('ge-modal', Modal)
app.component('ge-loading', Loading)
app.component('ge-alert', Alert)
app.component('ge-drop-file', DropFile)
// libs
app.provide('$sendRequest', _Request)
app.provide('$redirect', _redirect)
app.provide('$informativeDate', _informativeDate)
//mount
app.mount('#app')